<template>
  <div
    class="select sort-dropdown"
    :class="{'open': isOpenSelect}"
  >
    <div
      class="select-element"
      @click="selectHandler"
    >
      <span v-html="activeValue"></span>
      <img src="~/assets/img/svg/select/filter-chevron-down.svg">
    </div>

    <template v-if="isOpenSelect">
      <div class="select-dropdown">
        <template
          v-for="(option,id) in selectList"
          :key="`select-dropdown-itm-${id}`"
        >
          <div
            class="select-dropdown-row"
            @click="() => changeSort(option)"
          >
            <span>{{ option[labelName] }}</span>
            <span
              v-if="option?.price !== undefined "
              class="select-dropdown-row__extra-val"
            >{{ $priceFormat({amount:option?.price, decimalCount:0 }) }} ₽</span>
          </div>
        </template>
      </div>
      <div
        class="select-overlay"
        @click="selectHandler"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
const { selectList = [], activeValue = 'Выбрать', labelName = 'name' } = defineProps<{

  selectList?: Array<any>;
  activeValue?: string;
  labelName?: string;
}>();
const emits = defineEmits<{
  emitOptions: [ options: any ]
}>();
const { $priceFormat } = useNuxtApp();
const isOpenSelect = ref<boolean>(false);

const selectHandler = () => {
  isOpenSelect.value = !isOpenSelect.value;
};

const changeSort = (options: any) => {
  emits('emitOptions', options);
  selectHandler();
};
</script>

<style lang="scss">
.select-element {
  padding: 8px 30px 8px 10px;
	border-radius: 5px;
  color: var(--text-black);
  font-weight: 400;
  font-size: 16px;
  & > span{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  & img {
    top: 20px;
  }
}

.select-dropdown-row{
  display: flex;
  justify-content: space-between;
  color: var(--text-black);
  font-size: 16px;
  & > span {
    font-weight: 400;
  }
}
</style>
